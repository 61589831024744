/* eslint-disable no-unused-vars */
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiInstance, apiInstanceFetch } from "../../component/api/axiosApi";
import {  Success } from "../../component/api/toastServices";

const initialState = {
  expert: [],
  service : [],
  isLoading: false,
  isSkeleton: false,
  oneExpert: {},
  total:null
};

export const getAllExpert = createAsyncThunk("admin/expert/getAll", async (payload) => {
  
  return apiInstanceFetch.get(`admin/expert/getAll?start=${payload?.start}&limit=${payload?.limit}&search=${payload?.search}`);
});

export const getExpert = createAsyncThunk("admin/expert/getExpertDetails", async (id) => {

  return apiInstanceFetch.get(`admin/expert/getExpertDetails?expertId=${id}`);
});

export const expertAdd = createAsyncThunk("admin/expert", async (payload) => {

  return apiInstance.post("admin/expert", payload);
});

export const expertUpdate = createAsyncThunk("admin/expert/update", async (payload) => {
  
  return apiInstance.patch(`admin/expert/update/${payload?.expertId}`, payload?.formData);
})

export const expertDelete = createAsyncThunk("admin/expert/delete", async (id) => {

  return apiInstance.patch(`admin/expert/delete/${id}`)
})

export const verifyExpert = createAsyncThunk("admin/expert/isVerify", async (id) => {
  return apiInstance.put(`admin/expert/isVerify?${id}`)
})

export const blockExpert = createAsyncThunk("admin/expert/isBlock", async (id) => {

  return apiInstance.put(`admin/expert/isBlock?expertId=${id}`)
})

export const getAllServices1 = createAsyncThunk("admin/service/getAll", async (payload) => {
  return apiInstanceFetch.get(`admin/service/getAll?start=${payload.start}&limit=${payload.limit}&search=${payload?.search}`)
});


const expertSlice = createSlice({
  name: "expertSlice",
  initialState,
  reducers: {},
  // GetAll Expert
  extraReducers: (builder) => {
    builder.addCase(getAllExpert.pending, (state, action) => {
      state.isSkeleton = true;
    });

    builder.addCase(getAllExpert.fulfilled, (state, action) => {
      
      state.isSkeleton = false;
      state.expert = action?.payload?.experts;
      state.total = action?.payload?.total
    });

    builder.addCase(getAllExpert.rejected, (state, action) => {
      state.isSkeleton = false;
    });

    builder.addCase(getAllServices1.pending, (state, action) => {
      state.isSkeleton = true;
    });

    builder.addCase(getAllServices1.fulfilled, (state, action) => {
      state.isSkeleton = false;
      state.service = action?.payload?.services;
    });

    builder.addCase(getAllServices1.rejected, (state, action) => {
      state.isSkeleton = false;
    });


    builder.addCase(getExpert.pending, (state, action) => {
      state.isLoading = true;
    });

    builder.addCase(getExpert.fulfilled, (state, action) => {
      state.isLoading = false;
      state.oneExpert = action.payload?.experts;
    });

    builder.addCase(getExpert.rejected, (state, action) => {
      state.isLoading = false;
    });

    // add Expert

    builder.addCase(expertAdd.pending, (state, action) => {
      state.isLoading = true;
    });

    builder.addCase(expertAdd.fulfilled, (state, action) => {
      if (action.payload.status) {
        state.expert.unshift(action.payload?.expert);
        Success("Expert Create Successfully")
      }
      state.isLoading = false;

    });

    builder.addCase(expertAdd.rejected, (state, action) => {
      state.isLoading = false;
    });


    builder.addCase(expertUpdate.pending, (state, action) => {
      state.isLoading = true;
    });

    builder.addCase(expertUpdate.fulfilled, (state, action) => {
      if (action.payload.status) {
        const expertIdx = state.expert.findIndex(
          (expert) => expert._id === action.payload.expert._id
        );
        
        if (expertIdx !== -1) {
          state.expert[expertIdx] = {
            ...state.expert[expertIdx],
            ...action.payload.expert,
          };
        }
        state.service = action.payload.expert.serviceId
        Success("Expert Update Successfully");
      }
      state.isLoading = false;
    });

    
    

    builder.addCase(expertUpdate.rejected, (state, action) => {
      
      state.isLoading = false;
    });

    // delete Expert

    builder.addCase(expertDelete.pending, (state, action) => {
      state.isLoading = true;
    })

    builder.addCase(expertDelete.fulfilled, (state, action) => {
      if (action.payload.status) {
        state.expert = state.expert.filter((expert) => expert._id !== action.meta.arg);
        Success("Expert Delete Successfully")
      }
      state.isLoading = false;

    })
    builder.addCase(expertDelete.rejected, (state, action) => {
      state.isLoading = false;
    })

    // block unblock expert
    builder.addCase(blockExpert.pending, (state, action) => {
      // state.isLoading = true;
    })

    builder.addCase(blockExpert.fulfilled, (state, action) => {
      if (action.payload.status) {
        const updatedExpert = action.payload.expert; 
        const expertIndex = state.expert.findIndex(expert => expert?._id === updatedExpert?._id);
        if (expertIndex !== -1) {
                  
          state.expert[expertIndex] = { ...state.expert[expertIndex], ...action.payload?.expert };
        }
        Success("Expert Update Successfully")
      }
    })

    builder.addCase(blockExpert.rejected, (state, action) => {
      // state.isLoading = false;
    })


  },
});
export default expertSlice.reducer;
